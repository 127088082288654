<template>
  <Bar
    :chart-options="chartOptionsLocal"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { Bar } from 'vue-chartjs'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarGraph',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },

    chartData: {
      type: Object,
      default: () => {},
    },

    chartOptions: {
      type: Object,
      default: () => {},
    },

    stacked: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      chartOptionsLocal: {
        responsive: true,
        scales: {
          x: {
            stacked: this.stacked,
            categoryPercentage: 0.5,
            barPercentage: 1,
          },

          y: {
            stacked: this.stacked,
          },
        },

        elements: {
          bar: {
            borderRadius: 5,
          },
        },

        ...this.chartOptions,
      },
    }
  },
}
</script>
