<template>
  <Bar
    :chart-data="chartData"
    :chart-options="chartOptions"
    :plugins="plugins"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'

const horizonalLinePlugin = {
  id: 'horizontalLine',
  afterDraw: function (chartInstance) {
    let yValue
    let yScale = chartInstance.scales.y
    let canvas = chartInstance.chart
    let ctx = chartInstance.ctx
    let index
    let line
    let style

    if (chartInstance.options.horizontalLine) {
      for (
        index = 0;
        index < chartInstance.options.horizontalLine.length;
        index++
      ) {
        line = chartInstance.options.horizontalLine[index]

        if (!line.style) {
          style = '#080808'
        } else {
          style = line.style
        }

        if (line.y) {
          yValue = yScale.getPixelForValue(line.y)
        } else {
          yValue = 0
        }
        ctx.lineWidth = 3

        if (yValue) {
          window.chart = chartInstance
          ctx.beginPath()
          ctx.moveTo(0, yValue)
          ctx.lineTo(canvas.width, yValue)
          ctx.strokeStyle = style
          ctx.stroke()
        }

        if (line.text) {
          ctx.fillStyle = style
          ctx.fillText(line.text, 0, yValue + ctx.lineWidth)
        }
      }
      return
    }
  },
}
export default {
  name: 'StackedBar',
  components: {
    Bar,
  },

  props: {
    chartData: {
      type: Object,
      required: true,
    },
    styles: {
      type: Object,
      default: () => {},
    },

    chartConfig: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      plugins: [horizonalLinePlugin],

      chartOptions: {
        responsive: true,
        scales: {
          x: {
            stacked: true,
            categoryPercentage: 0.5,
            barPercentage: 1,
          },

          y: {
            stacked: true,
          },
        },

        elements: {
          bar: {
            borderRadius: 5,
          },
        },

        ...this.chartConfig,
      },
    }
  },
}
</script>
