<template>
  <v-sheet v-bind="$attrs">
    <slot />
    <input
      ref="input"
      type="file"
      :accept="accept"
      style="display: none"
      @change="handleFileSelect"
    />
  </v-sheet>
</template>
<script>
export default {
  name: 'FileDrop',
  props: {
    disabled: { type: Boolean, default: false },
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  data() {
    return {
      valid: false,
      dialog: true,
      loading: true,
      foto: null,
      telefones: null,
      overlay: false,
    }
  },
  mounted() {
    const dropzone = this.$el
    // register listeners on your dropzone / v-sheet
    if (dropzone) {
      // register all drag & drop event listeners
      dropzone.addEventListener('dragenter', e => {
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener('dragleave', e => {
        e.preventDefault()
        this.dragover = false
      })
      dropzone.addEventListener('dragover', e => {
        e.preventDefault()
        this.dragover = true
      })
      dropzone.addEventListener('drop', e => {
        e.preventDefault()
        const dragevent = e
        if (dragevent.dataTransfer) {
          this.filesSelected(dragevent.dataTransfer.files)
        }
      })
    }
  },
  methods: {
    activate() {
      this.$refs.input.click()
    },
    handleFileSelect(e) {
      this.filesSelected(e.target.files)
    },
    filesSelected(fileList) {
      if (this.disabled) {
        return
      }
      this.dragover = false
      this.$emit('files-selected', fileList)
      this.$emit('input')
      this.$refs.input.value = null
    },
  },
}
</script>
