<template>
  <v-card style="margin: 0px" color="primary" class="white--text">
    <v-card-title class="py-0" primary-title>
      <div class="d-flex flex-column" style="margin: 12px 0px; width: 100%">
        <div
          class="d-flex flex-column flex-md-row justify-md-space-between align-md-center"
        >
          <div class="d-flex flex-column flex-fill">
            <div>
              <span style="font-size: 16px; font-weight: 500">
                {{ title }}
              </span>
            </div>
            <slot />
          </div>
        </div>
      </div>
    </v-card-title>
  </v-card>
</template>

<script>
export default {
  name: 'HeaderList',
  props: {
    title: {
      type: String,
      default: 'Filtros',
    },
  },
}
</script>
