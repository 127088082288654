<template>
  <base-material-card
    :icon="icon"
    class="v-card--material-chart"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template v-slot:after-heading>
      <div>
        <div
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <h3
            class="display-2 pt-2 grey--text font-weight-light"
            v-text="title"
          />
        </div>
      </div>
    </template>
    <template v-slot:grafico>
      <div>
        <chartist
          v-if="data"
          :data="data"
          :event-handlers="eventHandlers"
          :options="options"
          :ratio="ratio"
          :responsive-options="responsiveOptions"
          :type="type"
        />
      </div>
    </template>

    <slot slot="reveal-actions" name="reveal-actions" />
    <slot />

    <slot slot="actions" name="actions" />
    <v-col v-if="subTextLeft || subTextRight" cols="12" class="px-0">
      <v-divider />
    </v-col>
    <v-row>
      <v-col class="pt-0 pb-0" cols="6">
        <div v-if="subTextLeft && subClickLeft">
          <v-icon
            :color="subIconColorLeft"
            size="16"
            class="ml-1 mr-1"
            @click="subClickLeft"
          >
            {{ subIconLeft }}
          </v-icon>
          <span
            :color="subTextColorLeft"
            class="caption grey--text font-weight-light"
            @click="subClickLeft"
            v-text="subTextLeft"
          />
        </div>
        <div v-else>
          <v-icon :color="subIconColorLeft" size="16" class="ml-1 mr-1">
            {{ subIconLeft }}
          </v-icon>
          <span
            :color="subTextColorLeft"
            class="caption grey--text font-weight-light"
            v-text="subTextLeft"
          />
        </div>
      </v-col>
      <v-col class="pt-0 pb-0" cols="6">
        <div v-if="subTextRight && subClickRight" class="text-right">
          <v-icon
            :color="subIconColorRight"
            size="16"
            class="ml-1 mr-1"
            @click="subClickRight"
          >
            {{ subIconRight }}
          </v-icon>
          <span
            :color="subTextColorRight"
            class="caption grey--text font-weight-light"
            @click="subClickRight"
            v-text="subTextRight"
          />
        </div>
        <div v-else class="text-right">
          <v-icon :color="subIconColorRight" size="16" class="ml-1 mr-1">
            {{ subIconRight }}
          </v-icon>
          <span
            :color="subTextColorRight"
            class="caption grey--text font-weight-light"
            v-text="subTextRight"
          />
        </div>
      </v-col>
    </v-row>
  </base-material-card>
</template>

<script>
export default {
  name: 'MaterialChartCard',

  inheritAttrs: false,

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    eventHandlers: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    ratio: {
      type: String,
      default: undefined,
    },
    responsiveOptions: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'Gráfico',
    },
    icon: {
      type: String,
      default: 'undefined',
    },
    subIconLeft: {
      type: String,
      default: undefined,
    },
    subIconColorLeft: {
      type: String,
      default: undefined,
    },
    subTextColorLeft: {
      type: String,
      default: undefined,
    },
    subTextLeft: {
      type: String,
      default: undefined,
    },
    subClickLeft: {
      type: Function,
      default: undefined,
    },
    subIconRight: {
      type: String,
      default: undefined,
    },
    subIconColorRight: {
      type: String,
      default: undefined,
    },
    subTextColorRight: {
      type: String,
      default: undefined,
    },
    subTextRight: {
      type: String,
      default: undefined,
    },
    subClickRight: {
      type: Function,
      default: undefined,
    },
    type: {
      type: String,
      required: true,
      validator: v => ['Bar', 'Line', 'Pie'].includes(v),
    },
  },
}
</script>

<style lang="sass">

.v-card--material-chart
  p
    color: #003677

  .v-card--material__heading

    .ct-label
      fill: #003677
      color: rgba(0,54,119)
      opacity: 10
      font-size: 0.800rem
      font-weight: 900

    .ct-bar
      stroke-width: 4%

    .ct-chart-bar
      overflow: visible
      margin : 0 0 30px 0

    .ct-chart-line
      overflow: visible
      margin : 0 0 30px 0

    .ct-char .ct-label .ct-horizontal .ct-end
      position: relative
      justify-content: flex-end
      text-align: center
      transform-origin: 100% 0
      transform: translate(-100%) rotate(-45deg)
      white-space: nowrap

    .ct-chart-donut .ct-label, .ct-chart-pie .ct-label
      fill: rgb(0,0,0)
      font-weight: 600

    .ct-chart-pie .ct-label
      fill: white
      font-weight: 400

    .ct-grid
      stroke: rgba(0,54,119) !important

    .ct-series-a .ct-point,
    .ct-series-a .ct-line,
    .ct-series-a .ct-bar,
    .ct-series-a .ct-slice-donut
        stroke: rgba(0,54,119)

    .ct-series-a .ct-slice-pie,
    .ct-series-a .ct-area
        fill: rgba(0,54,119)

    .ct-line.ct-threshold-above, .ct-point.ct-threshold-above, .ct-bar.ct-threshold-above
        stroke: #f05b4f

    .ct-line.ct-threshold-below, .ct-point.ct-threshold-below, .ct-bar.ct-threshold-below
        stroke: primary

    .ct-area.ct-threshold-above
        fill: #f05b4f

    .ct-area.ct-threshold-below
        fill: primary

    .ct-target-line
        stroke: #558b2f
        stroke-width: 3px
        stroke-dasharray: 4px
        shape-rendering: crispEdges
</style>
