<template>
  <v-row>
    <v-col cols="12" sm="6">
      <v-text-field
        v-model="form.Cep"
        v-mask="maskCep"
        type="text"
        label="CEP *"
        name="cep"
        required
        @blur="changeCep"
      />
    </v-col>
    <v-col cols="12" sm="6">
      <v-autocomplete
        v-model="form.estado"
        autocomplete="autocomplete_off_hack_xfr4!k"
        label="Estado *"
        name="estado"
        :items="estado"
        :rules="requiredRules"
        :loading="loadingEstados"
        :disabled="loadingEstados"
        item-text="Nome"
        item-value="CodEstado"
        required
        @change="changeCidade"
      />
    </v-col>
    <v-col cols="12" sm="6">
      <v-autocomplete
        v-model="form.CodCidade"
        name="cidade"
        autocomplete="autocomplete_off_hack_xfr4!k"
        label="Cidade *"
        :loading="loadingCidades"
        :items="cidade"
        :rules="requiredRules"
        item-text="Nome"
        item-value="CodCidade"
        required
      />
    </v-col>
    <v-col cols="4">
      <v-text-field v-model="form.Logradouro" label="Logradouro" />
    </v-col>
    <v-col cols="2">
      <v-text-field v-model="form.Numero" label="Número" />
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field v-model="form.Bairro" label="Bairro" />
    </v-col>
    <v-col cols="12" sm="6">
      <v-text-field v-model="form.Complemento" label="Complemento" />
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import EnderecoServices from '../../services/api/Endereco'

export default {
  name: 'Address',

  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: () => ({
    loading: true,
    loadingEstados: false,
    loadingCidades: false,

    estado: [],
    cidade: [],

    dataCep: null,

    form: {
      Cep: null,
      estado: null,
      CodCidade: null,
      Bairro: null,
      Logradouro: null,
      Numero: null,
      Complemento: null,
    },

    requiredRules: [v => !!v || '* Obrigatório'],

    maskCep: '#####-###',
  }),

  watch: {
    form: {
      handler() {
        this.$emit('input', this.form)
      },
      deep: true,
    },
  },

  created() {
    this.loadEstado()
  },

  mounted() {
    this.index()
  },

  methods: {
    async index() {
      if (!this.value.CodCidade) return

      this.form = this.value

      await this.loadEstado()

      const {
        data: { data },
      } = await EnderecoServices.findCity(this.value.CodCidade)

      this.form.estado = data.cidade.CodEstado
      this.form.CodCidade = data.cidade.CodCidade

      this.loadCidades(this.form.estado)
    },

    async changeCep() {
      if (this.form.Cep && this.form.Cep.length > 8) {
        const cep = this.form.Cep.replace(/\D/g, '')
        const { data } = await axios.get(
          `https://viacep.com.br/ws/${cep}/json/`,
        )

        this.form.estado = this.estado.find(
          item => item.Sigla === data.uf.toUpperCase(),
        ).CodEstado

        this.loadCidades(this.form.estado).then(cidades => {
          this.form.CodCidade = cidades.find(
            cidade => cidade.Nome == data.localidade,
          ).CodCidade
        })

        this.form.Logradouro = data.Logradouro || ''
        this.form.Bairro = data.Bairro || ''
        this.form.Complemento = data.Complemento || ''
      }
    },

    async loadEstado() {
      this.loadingEstados = true
      this.api.get.estados().then(data => {
        this.estado = data

        this.loadingEstados = false
      })
    },

    async loadCidades(CodEstado) {
      this.loadingCidades = true
      const data = await this.api.get.cidades(CodEstado)

      this.cidade = data
      this.loadingCidades = false

      return data
    },

    changeCidade() {
      this.loadCidades(this.form.estado)
    },

    async loadCidade(CodCidade) {
      const response = await this.api.get.cidade(CodCidade)

      return response
    },
  },
}
</script>
