<template>
  <p>
    {{ text ? text.slice(0, textLength) : '' }} {{ ' ' }}
    <a
      v-if="text && text.length > 100"
      class="blue--text"
      @click="showMoreText"
      >{{ showMore ? ' (Ler Menos)' : ' (Ler Mais)' }}</a
    >
  </p>
</template>

<script>
export default {
  name: 'ShowMore',

  props: {
    text: {
      type: String || null,
      required: true,
    },
  },

  data() {
    return {
      showMore: false,
      textLength: 100,
    }
  },

  methods: {
    showMoreText() {
      this.showMore = !this.showMore
      this.textLength = this.showMore ? this.text.length : 100
    },
  },
}
</script>
