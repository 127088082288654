<template>
  <v-dialog
    v-model="datetimeDialog"
    :persistent="persistent"
    width="290px"
    :fullscreen="fullscreen"
    @click:outside="closeDialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="published"
        readonly
        append-icon="mdi-calendar"
        :label="label"
        :filled="filled"
        v-bind="{ ...attrs, ...$attrs }"
        :rules="rules"
        v-on="on"
      ></v-text-field>
    </template>
    <v-card class="t-datetime-picker white">
      <v-toolbar height="36" color="primary" dark flat>
        <v-tabs v-model="tab" grow height="36">
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#date">
            <v-icon>mdi-calendar</v-icon>
          </v-tab>
          <v-tab href="#time">
            <v-icon>mdi-clock</v-icon>
          </v-tab>
          <v-btn depressed text class="rounded-0" @click="closeDialog">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-tabs>
      </v-toolbar>

      <v-tabs-items v-model="tab">
        <v-tab-item value="date">
          <v-date-picker
            v-model="date"
            class="rounded-0"
            full-width
            @input="tab = 'time'"
          >
          </v-date-picker>
        </v-tab-item>
        <v-tab-item value="time">
          <v-time-picker
            :key="tab"
            v-model="time"
            format="24hr"
            class="rounded-0"
            full-width
          >
            <v-btn text color="primary" @click="closeDialog"> OK </v-btn>
          </v-time-picker>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TDateTimeField',

  model: {
    prop: 'value',
    event: 'input',
  },

  emits: ['input', 'onchange'],

  props: {
    value: {
      type: String,
      default: null,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    dateFormat: {
      type: String,
      default: 'DD/MM/yyyy',
    },
    timeFormat: {
      type: String,
      default: 'HH:mm',
    },
    label: {
      type: String,
      default: 'Data e hora',
    },
    filled: {
      type: Boolean,
      default: false,
    },

    rules: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    date: null,
    time: null,
    tab: false,
    published: null,
    datetimeDialog: false,
  }),

  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (val) {
          this.published = this.moment(val).format(
            this.dateFormat + ' ' + this.timeFormat,
          )
          this.date = this.moment(val).format('yyyy-MM-DD')
          this.time = this.moment(val).format(this.timeFormat)
        }

        if (!val) {
          this.date = null
          this.time = null
          this.published = null
        }
      },
    },
  },

  methods: {
    closeDialog() {
      this.datetimeDialog = false

      if (!this.date) {
        this.date = this.moment().format('yyyy-MM-DD')
      }

      if (!this.time) {
        this.time = this.moment().format(this.timeFormat)
      }

      if (this.date && this.time) {
        this.published = this.moment(this.date + ' ' + this.time).format(
          this.dateFormat + ' ' + this.timeFormat,
        )
      }

      this.$emit('input', `${this.date} ${this.time}`)
      this.$emit('onchange', `${this.date} ${this.time}`)

      // prevents animate to date tab when closing
      setTimeout(() => (this.tab = 'date'), 300)
    },
  },
}
</script>

<style>
.v-tabs-slider-wrapper {
  top: 0;
}
.v-picker__title {
  height: 90px;
}
.v-time-picker-title__time * {
  font-size: 60px;
  height: 60px;
}
.v-picker__body {
  height: 290px;
}
.v-tabs-items {
  height: 380px;
}
.v-tab--active .v-icon {
  color: white;
}
</style>
