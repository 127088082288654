export const getProperty = (item, string) => {
  if (item === undefined || item === null) return ''
  const index = string.indexOf('.')
  const partial = string.substring(index + 1)
  if (index >= 0) {
    const prop = string.substring(0, index)
    if (prop in item) {
      return getProperty(item[prop], partial)
    } else return ''
  } else {
    if (string in item) {
      return item[string]
    } else return ''
  }
}
