<template>
  <v-dialog v-model="dialog" max-width="400px" max-height="400px" eager>
    <vue-cropper
      :key="23871289372189"
      ref="cropper2"
      hidden
      eager
      style="overflow: hidden"
      :guides="false"
      :view-mode="2"
      :aspect-ratio="1"
      :min-container-width="205"
      :min-container-height="300"
      drag-mode="move"
      :responsive="false"
      :auto-crop-area="1"
      :background="true"
      :rotatable="true"
      alt="Source Image"
      :check-cross-origin="false"
      @ready="cropSave2"
    />
    <template v-slot:activator="{ on }">
      <v-hover
        :style="`max-height: ${maxHei}; max-width: ${maxWid}; height: ${height};`"
        class="pa-0 ma-1"
      >
        <template v-slot:default="{ hover }">
          <div>
            <v-card class="pa-0 ma-0" fluid outlined>
              <base-file-drop
                ref="file-drop"
                :disabled="disableOnFill && loaded_image"
                @files-selected="handleFiles"
              >
                <v-img
                  class="rounded-sm"
                  :max-height="maxHei"
                  :max-width="maxWid"
                  :height="height"
                  :contain="true"
                  :src="showImage"
                />
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#d2d2d2" :z-index="1">
                    <div
                      v-if="!loaded_image"
                      class="d-flex flex-column align-center pa-2 pb-5"
                    >
                      <v-btn
                        class="pa-8"
                        icon
                        color="primary lighten-1"
                        @click="selectFiles"
                      >
                        <v-icon large> mdi-camera</v-icon>
                      </v-btn>
                      <span class="primary--text text--lighten-1 text-center">
                        <b>Escolha</b> uma imagem do computador
                      </span>
                      <span
                        class="primary--text text--lighten-1 text-center my-2"
                      >
                        ou
                      </span>
                      <span
                        class="primary--text text--lighten-1 text-center mt-1"
                      >
                        <b>arraste</b> um arquivo para cá
                      </span>
                    </div>
                    <div v-else>
                      <v-btn
                        v-if="editable"
                        class="pa-8"
                        icon
                        color="primary lighten-1"
                        v-on="on"
                        @click="att"
                      >
                        <v-icon large> mdi-pencil </v-icon>
                      </v-btn>
                      <v-btn
                        class="pa-8"
                        icon
                        color="primary lighten-1"
                        @click="reset"
                      >
                        <v-icon large> mdi-delete </v-icon>
                      </v-btn>
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </base-file-drop>
            </v-card>
          </div>
        </template>
      </v-hover>
    </template>
    <v-card justify="center" align="center">
      <v-toolbar color="primary" dark>
        <span class="headline">{{ dialogTitle }}</span>
      </v-toolbar>
      <v-container>
        <v-card-text>
          <v-card outlined class="mb-2 pa-0">
            <vue-cropper
              :key="id"
              ref="cropper"
              style="overflow: hidden"
              :img-style="{ width: '205px', height: '300px' }"
              :guides="false"
              :view-mode="2"
              :min-container-width="205"
              :min-container-height="300"
              drag-mode="move"
              :responsive="true"
              :auto-crop-area="1"
              :background="true"
              :rotatable="false"
              :zoomable="false"
              :aspect-ratio="1"
              alt="Source Image"
              :check-cross-origin="false"
              @ready="crop"
            />
          </v-card>
          <small>Recorte e ajuste o tamanho do imagem</small>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn color="error" outlined @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            @click="
              () => {
                cropSave()
                dialog = false
              }
            "
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import { v4 } from 'uuid'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  components: {
    VueCropper,
  },
  emits: ['delete'],
  model: {
    prop: 'image',
    event: 'setImage',
  },
  props: {
    image: {
      type: [String, Object],
      default: null,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    noData: {
      type: String,
      default: null,
    },
    disableOnFill: {
      type: Boolean,
      default: true,
    },
    dialogTitle: {
      type: String,
      default: 'Imagem',
    },
    maxHei: {
      type: String,
      default: '300px',
    },
    maxWid: {
      type: String,
      default: '205px',
    },
    height: {
      type: String,
      default: '300px',
    },
    width: {
      type: String,
      default: '205px',
    },
  },
  data() {
    return {
      id: v4(),
      dialog: false,
      loading: true,
      overlay: false,
      cropped_image: null,
    }
  },
  computed: {
    showImage() {
      return this.image || this.noData
    },
    loaded_image() {
      return !!this.image
    },
  },
  /* watch: {
      image () {
        this.cropped_image = this.image
      },
  }, */
  mounted() {
    if (this.image) {
      this.cropped_image = this.image
      this.$refs.cropper2.replace(this.image)
      this.cropSave2()
    } else {
      this.cropped_image = null
      this.$refs.cropper.reset()
    }
  },
  methods: {
    att() {
      this.$refs.cropper.replace(this.image)
    },
    handleFiles($event) {
      const reader = new FileReader($event[0])
      let image = null
      reader.onload = e => {
        image = e.target.result
        this.dialog = true
        this.$refs.cropper.replace(image)
        this.$emit('upload', image)
      }
      reader.readAsDataURL($event[0])
    },
    selectFiles() {
      this.$refs['file-drop'].activate()
    },
    crop() {
      // const cpcanvas = this.$refs.cropper.getCroppedCanvas({
      //   maxWidth: 1024,
      //   maxHeight: 1024,
      //   fillColor: '#fff',
      // })
      // this.cropped_image = cpcanvas.toDataURL('image')
      // this.$emit('setImage', this.cropped_image)
    },
    cropSave() {
      const cpcanvas = this.$refs.cropper.getCroppedCanvas({
        maxWidth: 205,
        maxHeight: 300,
        minWidth: 205,
        minHeight: 300,
        fillColor: '#fff',
      })
      if (cpcanvas) {
        this.cropped_image = cpcanvas.toDataURL('image')
        this.$emit('setImage', this.cropped_image)
      }
    },
    cropSave2() {
      const cpcanvas = this.$refs.cropper2.getCroppedCanvas({
        maxWidth: 205,
        maxHeight: 300,
        minWidth: 205,
        minHeight: 300,
        fillColor: '#fff',
      })
      if (cpcanvas) {
        this.cropped_image = cpcanvas.toDataURL('image')
        this.$emit('setImage', this.cropped_image)
      }
    },
    reset() {
      this.$refs.cropper.reset()
      this.cropped_image = null
      this.$emit('setImage', null)
      this.$emit('delete', null)
    },
  },
}
</script>
