import Swal from 'sweetalert2'
import Vue from 'vue'

function Toast(
  position = 'top-right',
  iconColor = 'white',
  timer = 5000,
  timerProgressBar = true,
  showConfirmButton = false,
) {
  return Swal.mixin({
    toast: true,
    position,
    iconColor,
    customClass: {
      popup: 'colored-toast',
    },
    showConfirmButton,
    timer,
    timerProgressBar,
  })
}

Vue.prototype.Toast = Toast

export default Toast
