// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import 'cropperjs/dist/cropper.css'
import VueMask from 'v-mask'
import Vue from 'vue'
import * as ChartJs from 'vue-chartjs'
import VueCropper from 'vue-cropperjs'
import VueGoogleCharts from 'vue-google-charts'
import VueRouterUserRoles from 'vue-router-user-roles'
import VuetifyMoney from 'vuetify-money'
import App from './App.vue'
import './api/api'
import './plugins/XLSX'
import './plugins/base'
import './plugins/chartist'
import './plugins/cruds'
import './plugins/googleMaps'
import './plugins/moment'
import './plugins/swal'
import './plugins/toast'
import vuetify from './plugins/vuetify'
import router from './routes/router'
import { load } from './services/AuthService'
import store from './vuex/store'

Vue.use(VueRouterUserRoles, { router })
Vue.use(VueMask)
Vue.use(VueGoogleCharts)
Vue.component(VueCropper)
Vue.use(ChartJs)
Vue.use(VuetifyMoney)
Vue.config.productionTip = false

// Criando prototype para remover o loading
// Element.prototype.remove = function () {
//   this.parentElement.removeChild(this)
// }
NodeList.prototype.remove = HTMLCollection.prototype.remove = function () {
  for (var i = this.length - 1; i >= 0; i--) {
    if (this[i] && this[i].parentElement) {
      this[i].parentElement.removeChild(this[i])
    }
  }
}

load().then(() => {
  document.getElementById('main-loading').remove()
  new Vue({
    store,
    vuetify,
    render: h => h(App),
    router,
  }).$mount('#app')
})
