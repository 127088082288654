<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  created() {
    const user = localStorage.getItem('user')
    this.$store.commit('SET_USER', JSON.parse(user))
  },
}
</script>
