import Vue from 'vue'
import Router from 'vue-router'
import admin from './admin'
import central from './central'
import consultor from './consultor'
import consultoria from './consultoria'
import gestor from './gestor'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    admin,
    gestor,
    consultor,
    central,
    consultoria,
    /* ACESSO GERAL */
    {
      name: 'login',
      path: '/login',
      component: () => import('@/views/login/Login'),
      meta: {
        permissions: [
          {
            role: 'consultor',
            access: false,
            redirect: 'consultor',
          },
          {
            role: 'central',
            access: false,
            redirect: 'central',
          },
          {
            role: 'gestor',
            access: false,
            redirect: 'gestor',
          },
          {
            role: 'admin',
            access: false,
            redirect: 'admin',
          },
        ],
      },
    },
    {
      name: 'verify email',
      path: '/verificar-email/:token',
      component: () => import('@/views/VerificarEmail'),
      meta: {
        permissions: [
          {
            role: 'consultor',
            access: false,
            redirect: 'consultor',
          },
          {
            role: 'central',
            access: false,
            redirect: 'central',
          },
          {
            role: 'gestor',
            access: false,
            redirect: 'gestor',
          },
          {
            role: 'admin',
            access: false,
            redirect: 'admin',
          },
        ],
      },
    },
    /* LANDPAGE */
    {
      name: 'land',
      path: '/',
      meta: {
        requiresAuth: true,
        permissions: [
          {
            role: 'guest',
            access: false,
            redirect: 'login',
          },
          {
            role: 'choose',
            access: false,
            redirect: 'roles',
          },
          {
            role: 'consultor',
            access: false,
            redirect: 'consultor',
          },
          {
            role: 'central',
            access: false,
            redirect: 'central',
          },
          {
            role: 'gestor',
            access: false,
            redirect: 'gestor',
          },
          {
            role: 'admin',
            access: false,
            redirect: 'admin',
          },
          {
            role: 'consultoria',
            access: false,
            redirect: 'consultoria',
          },
        ],
      },
    },
    {
      name: 'recuperar-senha',
      path: '/recuperar-senha',
      component: () => import('@/views/login/RecuperarSenha'),
      meta: {
        permissions: [
          {
            role: 'consultor',
            access: false,
            redirect: 'consultor',
          },
          {
            role: 'central',
            access: false,
            redirect: 'central',
          },
          {
            role: 'gestor',
            access: false,
            redirect: 'gestor',
          },
          {
            role: 'admin',
            access: false,
            redirect: 'admin',
          },
        ],
      },
    },
    // Escolhendo roles do usuário
    {
      name: 'roles',
      path: '/roles',
      component: () => import('@/views/ChooseRole'),
      meta: {
        requiresAuth: true,
      },
    },
    /* REDIRECIONAR ROTAS NÃO EXISTENTES PARA PÁGINA INICIAL */
    {
      path: '*',
      redirect: '/',
    },
  ],
})
