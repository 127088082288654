import store from '@/vuex/store'
import axios from 'axios'
import Swal from 'sweetalert2'
import { ENV_APP } from '../../env'

axios.defaults.baseURL = ENV_APP.apiUri

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
}

axios.interceptors.response.use(
  function (response) {
    return response
  },
  error => {
    if (error.response.status === 401) {
      if (store.state.user) {
        Swal.fire(
          'Ops!',
          'Sua sessão expirou ou você não tem autorização para acessar este componente. Faça o login novamente.',
          'error',
        )
      }
    }
    return Promise.reject(error)
  },
)

export default axios
