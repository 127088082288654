export default {
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  barImage: '@/assets/sidebar-1.jpg',
  drawer: null,
  user: null,
  relatorio_dataInicio: null,
  relatorio_dataFim: null,
  relatorio_search: null,
  crud_filter: null,
}
