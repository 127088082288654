const apiUri = 'https://api.plataformassist.com.br/api' // ambiente de produção;
const relUri = 'www.plataformassist.com.br'
const imgFileLogin = 'assist-logo.png'
const imgFileMenu = 'assist-branca-2.png'
const availableProjects = ['milk-plan-report', 'milk-plan-forms']
const ProjectID = '1'
const slug = 'milk-plan-report'

export const ENV_APP = {
    apiUri,
    relUri,
    imgFileLogin,
    imgFileMenu,
    availableProjects,
    ProjectID,
    slug,
}
