const permissions = [
  {
    role: 'admin',
    access: false,
    redirect: 'admin',
  },
  {
    role: 'central',
    access: false,
    redirect: 'central',
  },
  {
    role: 'gestor',
    access: false,
    redirect: 'gestor',
  },
  {
    role: 'guest',
    access: false,
    redirect: 'login',
  },
]

export default {
  /* ACESSO CONSULTOR*/
  name: 'consultor',
  path: '/consultor',
  redirect: '/consultor/dashboard',
  component: () => import('@/views/Index'),
  meta: { requiresAuth: true, permissions },
  children: [
    {
      path: 'dashboard',
      component: () => import('@/views/DashboardGestor'),
      meta: { title: 'Dashboard', requiresAuth: true, permissions },
    },
    {
      path: 'fazendas',
      component: () => import('@/views/fazendas/FazendaList'),
      meta: { title: 'Fazendas', requiresAuth: true, permissions },
    },
    {
      path: 'financeiros',
      component: () => import('@/views/financeiro/consultor/FinanceiroList'),
      meta: { title: 'Lançamento Financeiro', requiresAuth: true, permissions },
    },
    {
      path: 'financeiro/create',
      component: () => import('@/views/financeiro/consultor/index'),
      meta: { title: 'Lançamento Financeiro', requiresAuth: true, permissions },
    },
    {
      path: 'relatorios/geral',
      component: () => import('@/views/relatorios/geral/RelatorioList'),
      meta: {
        title: 'Visita Geral',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'schedules',
      component: () => import('@/views/visitas/index'),
      meta: { title: 'Visitas', requiresAuth: true, permissions },
    },
    {
      path: 'relatorios/geral/view',
      component: () => import('@/views/relatorios/geral/show/RelatorioView'),
      meta: {
        title: 'Visualização de relatório de Visita Geral',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/ccs',
      component: () => import('@/views/relatorios/ccs/RelatorioListCCS'),
      meta: {
        title: 'Visita CCS',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/ccs/view',
      component: () => import('@/views/relatorios/ccs/show/RelatorioViewCCS'),
      meta: {
        title: 'Visualização de relatório de Visita CCS',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/cpp',
      component: () => import('@/views/relatorios/cpp/RelatorioListCPP'),
      meta: {
        title: 'Visita CPP',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/cpp/view',
      component: () => import('@/views/relatorios/cpp/show/RelatorioViewCPP'),
      meta: {
        title: 'Visualização de relatório de Visita CPP',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/evoluileite',
      component: () =>
        import('@/views/relatorios/evoluiLeite/RelatorioListEvolui'),
      meta: {
        title: 'Visita Evolui Leite',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/evoluileite/view',
      component: () =>
        import('@/views/relatorios/evoluiLeite/show/RelatorioViewEvolui'),
      meta: {
        title: 'Visualização de Visita Evolui Leite',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/boaspraticas',
      component: () =>
        import('@/views/relatorios/boasPraticas/RelatorioListBP'),
      meta: {
        title: 'Visita de Boas Práticas',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/boaspraticas/view',
      component: () =>
        import('@/views/relatorios/boasPraticas/show/RelatorioViewBP'),
      meta: {
        title: 'Visualização de relatório Boas Práticas Agropecuárias',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/atb',
      component: () => import('@/views/relatorios/atb/RelatorioListATB'),
      meta: {
        title: 'Visita de Resíduos de ATB',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/atb/view',
      component: () => import('@/views/relatorios/atb/show/RelatorioViewATB'),
      meta: {
        title: 'Visualização de relatório de resíduos de ATB',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/fraudes',
      component: () => import('@/views/relatorios/fraudes/RelatorioListFraude'),
      meta: {
        title: 'Visita de Fraudes',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/fraudes/view',
      component: () =>
        import('@/views/relatorios/fraudes/show/RelatorioViewFraude'),
      meta: {
        title: 'Visualização de relatório de fraudes',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/bonolat',
      component: () =>
        import('@/views/relatorios/bonolat/RelatorioListBonolat'),
      meta: {
        title: 'Visita Bonolat',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/bonolat/view',
      component: () =>
        import('@/views/relatorios/bonolat/show/RelatorioViewBonolat'),
      meta: {
        title: 'Visualização de relatório de visita Bonolat',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/educampo',
      component: () => import('@/views/relatorios/educampo/RelatorioList'),
      meta: {
        title: 'Visita Educampo',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/educampo/view',
      component: () => import('@/views/relatorios/educampo/show/RelatorioView'),
      meta: {
        title: 'Visualização de relatório educampo',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/lactalis',
      component: () => import('@/views/relatorios/lactalis/RelatorioList'),
      meta: {
        title: 'Visita Lactalis',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/lactalis/view',
      component: () => import('@/views/relatorios/lactalis/show/RelatorioView'),
      meta: {
        title: 'Visualização de relatório Lactalis',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/lactaleite',
      component: () => import('@/views/relatorios/lactaleite/RelatorioList'),
      meta: {
        title: 'Visita relatório lactaleite',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/solidos',
      component: () => import('@/views/relatorios/solidos/RelatorioList'),
      meta: {
        title: 'Visita relatório Sólidos',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/solidos/view',
      component: () => import('@/views/relatorios/solidos/show/RelatorioView'),
      meta: {
        title: 'Visualização do relatório Sólidos',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/promilat',
      component: () => import('@/views/relatorios/promilat/RelatorioList'),
      meta: {
        title: 'Visita relatório Promilat',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/promilat/view',
      component: () => import('@/views/relatorios/promilat/show/RelatorioView'),
      meta: {
        title: 'Visualização do relatório Promilat',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'relatorios/lactaleite/view',
      component: () =>
        import('@/views/relatorios/lactaleite/show/RelatorioView'),
      meta: {
        title: 'Visualização do relatório lactaleite',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'trocar-senha',
      component: () => import('@/views/ChangePassword'),
      meta: {
        title: 'Atualizar senha',
        requiresAuth: true,
        permissions,
      },
    },
    {
      path: 'perfil',
      component: () => import('@/views/perfil/UserForm'),
      meta: { title: 'Perfil', requiresAuth: true, permissions },
    },
  ],
}
