<template>
  <div>
    <v-row class="mx-auto" max-width="1000">
      <v-col cols="6">
        <h6>CCS</h6>
        <v-range-slider
          v-model="rangeCCS"
          :max="max"
          :min="min"
          class="align-center"
          @change="filter"
        >
          <template v-slot:prepend>
            <v-text-field
              :value="rangeCCS[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(rangeCCS, 0, $event)"
              @keyup="filter"
              @keydown="filter"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              :value="rangeCCS[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(rangeCCS, 1, $event)"
              @keyup="filter"
              @keydown="filter"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-col>
      <v-col cols="6">
        <h6>CPP</h6>
        <v-range-slider
          v-model="rangeCPP"
          :max="max"
          :min="min"
          hide-details
          class="align-center"
          @change="filter"
        >
          <template v-slot:prepend>
            <v-text-field
              :value="rangeCPP[0]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(rangeCPP, 0, $event)"
              @keyup="filter"
              @keydown="filter"
            ></v-text-field>
          </template>
          <template v-slot:append>
            <v-text-field
              :value="rangeCPP[1]"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
              @change="$set(rangeCPP, 1, $event)"
              @keyup="filter"
              @keydown="filter"
            ></v-text-field>
          </template>
        </v-range-slider>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="graficoConsultor">
        <Scatter
          :chart-options="chartOptions"
          :chart-data="data"
          :chart-id="chartId"
          :dataset-id-key="datasetIdKey"
          :plugins="plugins"
          :css-classes="cssClasses"
          :styles="styles"
          :width="width"
          :height="height"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js'
import ChartsPluginAnnotation from 'chartjs-plugin-annotation'
import { Scatter } from 'vue-chartjs'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartsPluginAnnotation,
)
export default {
  name: 'ScatterGraph',
  components: {
    Scatter,
  },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart',
    },
    datasetIdKey: {
      type: String,
      default: 'label',
    },
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
    cssClasses: {
      default: '',
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },

    chartData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      chartOptions: {
        responsive: true,
        scales: {
          yAxes: {
            title: {
              display: true,
              text: 'CCS',
              font: {
                size: 15,
              },
            },
            ticks: {
              precision: 0,
            },
          },
          xAxes: {
            title: {
              display: true,
              text: 'CPP',
              min: 1000,
              font: {
                size: 15,
              },
            },
          },
        },
        plugins: {
          backgroundColor: '#313a40',
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: false,
            position: 'nearest',

            external: context => {
              const { tooltip } = context
              const { x, y, produtor, propriedade } = tooltip.dataPoints[0].raw
              // Tooltip Element
              let tooltipEl = document.getElementById('chartjs-tooltip')

              // Create element on first render
              if (!tooltipEl) {
                tooltipEl = document.createElement('div')
                tooltipEl.id = 'chartjs-tooltip'
                tooltipEl.innerHTML = '<table></table>'
                document.body.appendChild(tooltipEl)
              }

              // Hide if no tooltip
              const tooltipModel = context.tooltip
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0'
                return
              }

              // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
              tooltipEl.classList.remove('below', 'no-transform')

              // Set HTML & Data
              if (tooltipModel.body) {
                const innerHtml = `
                        <div style="border-collapse: separate; overflow: hidden; border-radius: 3px; box-shadow: 0 6px 12px rgba(0,0,0,.15); opacity: 1; color: white; transition: all .1s ease; transform: translate(-50%, 0)">
                            <div style="display: flex; padding: 1.2rem; background-color: rgba(0, 0, 0, 0.7)">
                                <div style="display: flex; margin-right: 1.2rem;align-items: center;  ">
                                    <div style="border-radius: 100%; background-color: rgb(40, 65, 106, 0.7); height: 13px; width: 13px;"></div>
                                </div>
                                <div style="display: flex;  flex-direction: column;  font-family: 'Roboto'; font-size: 14px; color: white">
                                    <div style="text-align: center">
                                      <span style="font-weight: bold">CCS x CPP</span>
                                    </div>
                                    <br />
                                    <div>Produtor: <span style="font-weight: 600">${produtor}</span></div>
                                    <br />
                                    <div>Propriedade: <span style="font-weight: 600">${propriedade}</span></div>
                                    <br />
                                    <div>CCS x CPP: <span style="font-weight: 600">${y} x ${x}</span></div>
                                </div>
                            </div>
                         </div>
                    `

                tooltipEl.querySelector('table').innerHTML = innerHtml
              }

              const position = context.chart.canvas.getBoundingClientRect()

              // Display, position, and set styles for font
              tooltipEl.style.opacity = '1'
              tooltipEl.style.position = 'absolute'
              tooltipEl.style.left =
                position.left + window.pageXOffset + tooltipModel.caretX + 'px'
              tooltipEl.style.top =
                position.top + window.pageYOffset + tooltipModel.caretY + 'px'
              tooltipEl.style.padding =
                tooltipModel.padding + 'px ' + tooltipModel.padding + 'px'
              tooltipEl.style.pointerEvents = 'none'
            },
          },
          annotation: {
            annotations: {
              line1: {
                type: 'line',
                yMin: 500,
                yMax: 500,
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
              },
              line2: {
                type: 'line',
                xMin: 300,
                xMax: 300,
                borderColor: 'rgb(102, 255, 99)',
                borderWidth: 2,
              },
            },
          },
        },
      },

      data: { ...this.chartData },
      chartDataBkp: JSON.parse(JSON.stringify(this.chartData)),

      min: 0,
      max: 10000,
      rangeCCS: [0, 1000],
      rangeCPP: [0, 1000],
    }
  },

  mounted() {},

  methods: {
    filter() {
      const data = this.chartDataBkp.datasets[0].data.filter(item => {
        return (
          item.x >= this.rangeCPP[0] &&
          item.x <= this.rangeCPP[1] &&
          item.y >= this.rangeCCS[0] &&
          item.y <= this.rangeCCS[1]
        )
      })

      this.data.datasets[0].data = data
    },
  },
}
</script>
