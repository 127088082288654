export default {
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload
  },
  SET_USER(state, payload) {
    state.user = payload
  },
  SET_RELATORIO_DATAINICIO(state, payload) {
    state.relatorio_dataInicio = payload
  },
  SET_RELATORIO_DATAFIM(state, payload) {
    state.relatorio_dataFim = payload
  },
  SET_RELATORIO_SEARCH(state, payload) {
    state.relatorio_search = payload
  },
  SET_CRUD_FILTER(state, payload) {
    state.crud_filter = payload
  },
}
