<template>
  <v-data-table
    v-if="customSort"
    id="table-graph"
    v-fixed-columns="true"
    :headers="headers"
    :items="items"
    :items-per-page="itemsPerPage"
    :hide-default-footer="hideDefaultfooter"
    :footer-props="{
      disablePagination: disablePagination,
    }"
    :item-class="row_classes"
    :custom-sort="customSort"
    :class="conditionalStyle()"
    :slots="slots"
  >
    <template v-slot:[`item.actions`]="{ item }">
      <div style="display: flex !important; flex-direction: row">
        <v-icon
          v-for="(action, index) in actions"
          :key="index"
          small
          :title="action.title"
          :color="action.color"
          class="mr-1"
          @click="action.click(item)"
        >
          {{ action.icon }}
        </v-icon>

        <div v-for="(action, index) in actions" :key="index + 'div'">
          <v-checkbox
            v-if="action.title === 'checkbox'"
            :key="index + 'checkbox'"
            v-model="item.checked"
            @click="action.click(item)"
          ></v-checkbox>
        </div>
      </div>
    </template>
    <template v-for="slot in slots" v-slot:[slot]="{ item }">
      <slot :name="slot" :item="item" />
    </template>
  </v-data-table>
</template>
<script>
/* eslint-disable */

const style = document.createElement('style')
style.type = 'text/css'

const applyCallback = (el, binding, vnode) => {
  if (!binding.value) return
  const columns = vnode.componentOptions?.propsData?.headers
  const showSelect =
    vnode.componentOptions?.propsData?.showSelect !== undefined || false

  const fixedIndexes = []
  columns?.forEach((column, columnIdx) => {
    if (column.fixed) {
      fixedIndexes.push(columnIdx + (showSelect ? 2 : 1))
    }
  })
  if (showSelect) {
    fixedIndexes.push(1)
  }

  const uniqId =
    Date.now().toString(36) + Math.random().toString(36).substring(2)
  el.setAttribute('v-fixed-columns', uniqId)

  let css = `[v-fixed-columns="${uniqId}"] tbody > tr:hover > td,
  [v-fixed-columns="${uniqId}"] tbody > tr.v-data-table__selected > td {
    background: inherit !important;
  }`

  fixedIndexes.forEach((index, indexIdx) => {
    css += `[v-fixed-columns] tbody > tr > td:nth-child(${index}), [v-fixed-columns="${uniqId}"] thead > tr > th:nth-child(${index})`
    if (indexIdx < fixedIndexes.length - 1) {
      css += ','
    }
  })

  css +=
    '{ position: sticky !important; position: -webkit-sticky !important; left: 0; z-index: 3; background: #1f497d; color: white;}'

  fixedIndexes?.forEach((index, indexIdx) => {
    css += `tbody > tr > td:nth-child(${index}), .v-data-table__wrapper > table > thead > tr > th:nth-child(${index})`

    if (indexIdx < fixedIndexes.length - 1) {
      css += ','
    }
  })

  css += '{ z-index: 4;}'

  let left = 0

  columns?.forEach((column, columnIdx) => {
    if (column.fixed) {
      const index = columnIdx + (showSelect ? 2 : 1)
      css += `[v-fixed-columns="${uniqId}"] tbody > tr > td:nth-child(${index}), [v-fixed-columns="${uniqId}"] thead > tr > th:nth-child(${index}) { left: ${
        left + (showSelect ? 64 : 0)
      }px !important;}`
      left += (column.width || 0) + left
    }
  })

  style.innerHTML = css
  document.head.appendChild(style)
}

const unbindCallback = (el, binding) => {
  style.innerHTML = ''
  el.removeAttribute('v-fixed-columns')
  // style.remove()
}

export default {
  name: 'TableGraph',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    itemsPerPage: {
      type: Number,
      default: 10,
    },
    headers: {
      type: Array,
      default: () => [],
    },

    customSort: {
      type: Function,
      default: Object,
    },

    disablePagination: {
      type: Boolean,
      default: true,
    },

    hideDefaultfooter: {
      type: Boolean,
      default: true,
    },

    vFixedColumns: {
      type: Boolean,
      default: false,
    },

    slots: {
      type: Array,
      default: () => [],
    },
    actions: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    row_classes(item) {
      if (item.class === 'blue-row') {
        return 'blue-row'
      }
    },

    conditionalStyle() {
      let css = 'table-graph elevation-1 mb-8 rounded'
      if (this.vFixedColumns) {
        return css + ' fixed-table'
      }

      return css
    },
  },

  directives: {
    fixedColumns: {
      bind(el, binding, vnode) {
        applyCallback(el, binding, vnode)
      },
      componentUpdated(el, binding, vnode) {
        applyCallback(el, binding, vnode)
      },
      unbind(el, binding) {
        unbindCallback(el, binding)
      },
    },
  },
}
</script>

<style>
.v-data-table-header {
  background-color: #1f497d !important;
  color: #fff;
}

#table-graph .text-start > span {
  color: #fff;
}

.table-graph .text-start > span {
  color: #fff;
}

.blue-row {
  background-color: #1f497d !important;
  color: #fff;
}

.fixed-table tbody tr:hover td {
  color: black !important;
}

.v-date-picker-table {
  background-color: white !important;
}

.v-date-picker-table > table > thead > tr > th {
  background-color: white;
}

.v-date-picker-table > table > tbody > tr > td {
  background-color: white;
}

.table-graph thead th:first-child {
  border-radius: 8px 0 0 0;
}

.table-graph thead th:last-child {
  border-radius: 0 8px 0 0;
}
</style>
