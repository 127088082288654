import Vue from 'vue'
import axios from '../api/axios'

const load = () => {
  const user = localStorage.getItem('user')
  Vue.prototype.$user.set(JSON.parse(user))

  return new Promise(resolve => {
    axios
      .post('auth/me')
      .then(response => {
        if (response) {
          const oldUser = JSON.parse(localStorage.getItem('user'))
          localStorage.setItem('roles', JSON.stringify(response.data.roles))

          response.data.role = oldUser.role
          Vue.prototype.$user.set(response.data)
          localStorage.setItem('user', JSON.stringify(response.data))
        } else {
          Vue.prototype.$user.set({ role: 'guest' })
        }
        resolve()
      })
      .catch(() => {
        Vue.prototype.$user.set({ role: 'guest' })
        resolve()
      })
  })
}

const logout = () => {
  return new Promise(resolve => {
    const forceLogout = () => {
      Vue.prototype.$user.set({ role: 'guest' })
      localStorage.removeItem('user-token')
    }
    axios
      .post('auth/logout')
      .then(() => {
        forceLogout()
        resolve()
      })
      .catch(() => {
        forceLogout()
        resolve()
      })
  })
}

const login = (user, token) => {
  axios.defaults.headers.common.Authorization = 'Bearer ' + token
  localStorage.setItem('user-token', token)

  localStorage.setItem('roles', JSON.stringify(user.roles))

  if (user.roles.length > 1) {
    user.role = 'choose'
    Vue.prototype.$user.set(user)
  } else {
    user.role = user.roles[0].slug
    Vue.prototype.$user.set(user)
  }

  localStorage.setItem('user', JSON.stringify(user))
  Vue.prototype.$user.set(user)
}

export { load, login, logout }
